<template>
  <dl class="cMenuItem js-scroll-target" v-for="menuItem of menuList" :key="menuItem.id">
    <dt>
      <span class="cMenuItem__title">{{ menuItem.title }}</span>
      <span class="cMenuItem__subTitle" v-html="menuItem.subTitle"></span>
    </dt>

    <dd>
      <p class="cMenuItem__text" v-html="menuItem.text"></p>

      <ul class="cMenuItem__priceList">
        <li class="cMenuItem__priceItem" v-for="priceItem of menuItem.priceList" :key="priceItem.id">
          <span class="cMenuItem__price">{{ priceItem.price }}</span>

          <span class="cMenuItem__priceText" v-html="priceItem.text"></span>
        </li>
      </ul>
    </dd>
  </dl>
</template>

<script>
export default {
  name: "BaseMenu",
  props: ["menuList"],
};
</script>

<style lang="scss" scoped>
.cMenuItem {
  & dt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  & dd {
    margin-top: 1.5rem;
  }

  & + & {
    margin-top: 5rem;
  }

  &__title {
    font-family: var(--lato);
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 0.1em;
  }

  &__subTitle {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0.1em;
    margin-top: 0.8rem;
  }

  &__text {
    font-size: 1.2rem;
    letter-spacing: 0.1em;
    font-weight: 400;
    line-height: 2.3;
  }

  &__priceList {
    margin-top: 2.5rem;
  }

  &__priceItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & + li {
      margin-top: 1.5rem;
    }
  }

  &__price {
    font-family: var(--lato);
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 0.05em;
  }

  &__priceText {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.9;
    letter-spacing: 0.05em;
    margin-top: 0.5rem;
  }
}

@include g.responsive(sm) {
  .cMenuItem {
    &__priceItem {
      flex-direction: row;
      align-items: baseline;
    }

    &__price {
      flex-shrink: 0;
      margin-right: 0.7rem;
    }

    &__priceText {
      margin-top: 0;
    }
  }
}
</style>
