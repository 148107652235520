<template>
  <div class="lContainer">
    <main class="lMain">
      <div class="lFV">
        <div class="lFV__wrapper">
          <div class="lFV__logo">
            <img src="@/assets/image/fv-logo.svg" alt="ロゴ画像" />
          </div>

          <div class="lFV__headline">
            <h1 class="lFV__h1">menu</h1>
          </div>

          <div class="lFixed__imageText lFixed__imageText--pc">
            丁寧に、大切に。
            <br />
            手で触れるということ
            <br />
            心を込めて癒します。
          </div>
        </div>
      </div>

      <div class="lFixed">
        <div class="lFixed__wrapper">
          <div class="lFixed__block lFixed__block--image lFixed__block--image01">
            <div class="lFixed__scroller">
              <div class="lFixed__image">
                <picture>
                  <source
                    type="image/webp"
                    media="(min-width: 768px)"
                    srcset="
                      @/assets/image/webp/menu-fv-image-pc.webp    1x,
                      @/assets/image/webp/menu-fv-image-pc@2x.webp 2x
                    "
                  />
                  <source
                    media="(min-width: 768px)"
                    srcset="@/assets/image/menu-fv-image-pc.jpg 1x, @/assets/image/menu-fv-image-pc@2x.jpg 2x"
                  />
                  <source
                    type="image/webp"
                    srcset="@/assets/image/webp/menu-fv-image.webp 1x, @/assets/image/webp/menu-fv-image@2x.webp 2x"
                  />
                  <img
                    src="@/assets/image/menu-fv-image.jpg"
                    srcset="@/assets/image/menu-fv-image@2x.jpg 2x"
                    decoding="async"
                    width="375"
                    height="768"
                    alt=""
                  />
                </picture>

                <div class="lFixed__imageText lFixed__imageText--sp">
                  丁寧に、大切に。
                  <br />
                  手で触れるということ
                  <br />
                  心を込めて癒します。
                </div>
              </div>
            </div>
          </div>

          <div class="lFixed__block lFixed__block--text lFixed__block--text01">
            <div class="lFixed__scroller">
              <div class="lFixed__left">
                <section class="lSection lSection--first">
                  <p class="lSection__text js-scroll-target">
                    polepoleがご提供しているのは、
                    <br />
                    手のぬくもりによる癒やしと、
                    <br />
                    日々の忙しさを忘れられるプライベートな空間。
                    <br />
                    おひとりまたはカップルでご利用いただけます。
                  </p>

                  <div class="lSection__h2 js-scroll-target">menu list</div>

                  <div class="lSection__content">
                    <MenuItem :menuList="menuList"></MenuItem>

                    <div class="lSection__attention js-scroll-target">
                      <p>
                        足湯（冬季）やカウンセリング、お着替えの時間などがありますので、ご希望のコースにプラス30分程度お時間を頂いております。
                      </p>
                    </div>

                    <div class="lSection__modal js-scroll-target">
                      <button type="button" data-target="modal01" @click="modalOpen">
                        禁忌事項について
                        <span class="icon"></span>
                      </button>
                    </div>

                    <div class="lSection__image js-scroll-target">
                      <picture>
                        <source
                          type="image/webp"
                          media="(min-width: 768px)"
                          srcset="
                            @/assets/image/webp/top-hand02-pc.webp    1x,
                            @/assets/image/webp/top-hand02-pc@2x.webp 2x
                          "
                        />
                        <source
                          media="(min-width: 768px)"
                          srcset="@/assets/image/top-hand02-pc.png 1x, @/assets/image/top-hand02-pc@2x.png 2x"
                        />
                        <source
                          type="image/webp"
                          srcset="@/assets/image/webp/top-hand02.webp 1x, @/assets/image/webp/top-hand02@2x.webp 2x"
                        />
                        <img
                          src="@/assets/image/top-hand02.png"
                          srcset="@/assets/image/top-hand02@2x.png 2x"
                          decoding="async"
                          width="375"
                          height="239"
                          alt="手のイラスト その2"
                        />
                      </picture>
                    </div>

                    <div class="lTopic">
                      <div class="lTopic__headline">
                        <h2 class="lSection__h2">topic</h2>
                      </div>

                      <div class="lTopic__body">
                        <ul class="lTopic__list">
                          <li class="lTopic__item">
                            <div class="lTopic__content">
                              <MenuItem :menuList="topicItem01"></MenuItem>

                              <p class="lTopic__text js-scroll-target">
                                背面のオイルトリートメント
                                <br />
                                お1人様ずつ順番に施術を受けていただけるようWEB限定でご用意したショートコースになります。
                                <br />
                                足湯をご用意しておりますのでお待ちいただいている間にゆっくりとおくつろぎください。
                              </p>
                            </div>

                            <div class="lTopic__image">
                              <picture>
                                <source
                                  type="image/webp"
                                  media="(min-width: 768px)"
                                  srcset="
                                    @/assets/image/webp/menu-topic-image01-pc.webp    1x,
                                    @/assets/image/webp/menu-topic-image01-pc@2x.webp 2x
                                  "
                                />
                                <source
                                  media="(min-width: 768px)"
                                  srcset="
                                    @/assets/image/menu-topic-image01-pc.jpg    1x,
                                    @/assets/image/menu-topic-image01-pc@2x.jpg 2x
                                  "
                                />
                                <source
                                  type="image/webp"
                                  srcset="
                                    @/assets/image/webp/menu-topic-image01.webp    1x,
                                    @/assets/image/webp/menu-topic-image01@2x.webp 2x
                                  "
                                />
                                <img
                                  src="@/assets/image/menu-topic-image01.jpg"
                                  srcset="@/assets/image/menu-topic-image01@2x.jpg 2x"
                                  decoding="async"
                                  width="289"
                                  height="289"
                                  alt=""
                                />
                              </picture>
                            </div>
                          </li>

                          <li class="lTopic__item">
                            <div class="lTopic__content">
                              <MenuItem :menuList="topicItem02"></MenuItem>
                            </div>

                            <div class="lTopic__image">
                              <picture>
                                <source
                                  type="image/webp"
                                  media="(min-width: 768px)"
                                  srcset="
                                    @/assets/image/webp/menu-topic-image02-pc.webp    1x,
                                    @/assets/image/webp/menu-topic-image02-pc@2x.webp 2x
                                  "
                                />
                                <source
                                  media="(min-width: 768px)"
                                  srcset="
                                    @/assets/image/menu-topic-image02-pc.jpg    1x,
                                    @/assets/image/menu-topic-image02-pc@2x.jpg 2x
                                  "
                                />
                                <source
                                  type="image/webp"
                                  srcset="
                                    @/assets/image/webp/menu-topic-image02.webp    1x,
                                    @/assets/image/webp/menu-topic-image02@2x.webp 2x
                                  "
                                />
                                <img
                                  src="@/assets/image/menu-topic-image02.jpg"
                                  srcset="@/assets/image/menu-topic-image02@2x.jpg 2x"
                                  decoding="async"
                                  width="289"
                                  height="289"
                                  alt=""
                                />
                              </picture>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>

                <BaseAccess class="lSection--third" />
              </div>

              <div class="lSlider">
                <div class="lSlider__wrapper">
                  <swiper
                    class="lSlider__swiper"
                    :speed="1600"
                    :effect="'fade'"
                    :fadeEffect="{
                      crossFade: true,
                    }"
                    :loop="true"
                    :centeredSlides="true"
                    :autoplay="{
                      delay: 4000,
                      disableOnInteraction: false,
                    }"
                  >
                    <swiper-slide class="lSlider__item">
                      <div class="lSlider__itemWrapper">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="
                              @/assets/image/webp/menu-slider-image01-pc.webp    1x,
                              @/assets/image/webp/menu-slider-image01-pc@2x.webp 2x
                            "
                          />
                          <img
                            src="@/assets/image/menu-slider-image01-pc.jpg"
                            srcset="@/assets/image/menu-slider-image01-pc@2x.jpg 2x"
                            decoding="async"
                            width="728"
                            height="768"
                            alt=""
                          />
                        </picture>
                      </div>
                    </swiper-slide>

                    <swiper-slide class="lSlider__item">
                      <div class="lSlider__itemWrapper">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="
                              @/assets/image/webp/menu-slider-image02-pc.webp    1x,
                              @/assets/image/webp/menu-slider-image02-pc@2x.webp 2x
                            "
                          />
                          <img
                            src="@/assets/image/menu-slider-image02-pc.jpg"
                            srcset="@/assets/image/menu-slider-image02-pc@2x.jpg 2x"
                            decoding="async"
                            width="728"
                            height="768"
                            alt=""
                          />
                        </picture>
                      </div>
                    </swiper-slide>

                    <swiper-slide class="lSlider__item">
                      <div class="lSlider__itemWrapper">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="
                              @/assets/image/webp/menu-slider-image03-pc.webp    1x,
                              @/assets/image/webp/menu-slider-image03-pc@2x.webp 2x
                            "
                          />
                          <img
                            src="@/assets/image/menu-slider-image03-pc.jpg"
                            srcset="@/assets/image/menu-slider-image03-pc@2x.jpg 2x"
                            decoding="async"
                            width="728"
                            height="768"
                            alt=""
                          />
                        </picture>
                      </div>
                    </swiper-slide>

                    <swiper-slide class="lSlider__item">
                      <div class="lSlider__itemWrapper">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="
                              @/assets/image/webp/menu-slider-image04-pc.webp    1x,
                              @/assets/image/webp/menu-slider-image04-pc@2x.webp 2x
                            "
                          />
                          <img
                            src="@/assets/image/menu-slider-image04-pc.jpg"
                            srcset="@/assets/image/menu-slider-image04-pc@2x.jpg 2x"
                            decoding="async"
                            width="728"
                            height="768"
                            alt=""
                          />
                        </picture>
                      </div>
                    </swiper-slide>

                    <swiper-slide class="lSlider__item">
                      <div class="lSlider__itemWrapper">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="
                              @/assets/image/webp/menu-slider-image05-pc.webp    1x,
                              @/assets/image/webp/menu-slider-image05-pc@2x.webp 2x
                            "
                          />
                          <img
                            src="@/assets/image/menu-slider-image05-pc.jpg"
                            srcset="@/assets/image/menu-slider-image05-pc@2x.jpg 2x"
                            decoding="async"
                            width="728"
                            height="768"
                            alt=""
                          />
                        </picture>
                      </div>
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </div>
          </div>

          <div class="lFixed__block lFixed__block--image lFixed__block--image02">
            <div class="lFixed__scroller">
              <div class="lFixed__image">
                <img src="@/assets/image/top-slide02.jpg" alt="" />
              </div>
            </div>
          </div>

          <div class="lFixed__block lFixed__block--text">
            <div class="lFixed__scroller">
              <BaseAccess class="lSection--second" />
            </div>
          </div>
        </div>
      </div>
    </main>

    <Footer></Footer>

    <div
      id="modal01"
      class="lModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalHeader"
      aria-hidden="true"
      data-backdrop="true"
    >
      <div class="lModal__overlay" data-target="modal01" @click.self="modalClose">
        <div class="lModal__wrapper">
          <div class="lModal__content" role="document">
            <div class="lModal__block">
              <h2 id="modalHeader">アロマトリートメント後の注意点</h2>
              <p>当店の施術はリラクゼーションを目的に行うものであり、治療を目的にしたものではありません。</p>

              <p>当店ではお客様への医療、治療行為を行いません。</p>

              <p>
                施術終了後に当店外で発症し、医師による因果関係の証明不能の傷病につきましては、当店では責任を負いかねますのでご了承下さい。
              </p>

              <p>お客様の体調、既住歴、持病等によっては、施術をお断りさせていただく場合がございます。</p>

              <p>
                ・心臓病、高血圧、腎臓病、糖尿病、てんかんなどのご病気の方
                <br />
                ・悪性腫瘍、動脈瘤、動脈硬化、静脈瘤のある方
                <br />
                ・半年以内に手術をされた方、又は著しく体力が低下している方
                <br />
                ・現在お薬を服用中の方
                <br />
                ・伝染性皮膚炎、重度の水虫、皮膚に炎症がある方
                <br />
                ・怪我、火傷、打撲、捻挫などの炎症、肉離れを起こしている方
                <br />
                ・アレルギーの方
                <br />
                ・37度以上の発熱がある方
                <br />
                ・感染症のある方
                <br />
                ・泥酔されている方
                <br />
                ・妊娠中の方
                <br />
              </p>
            </div>

            <div class="lModal__block">
              <h2 id="modalHeader">禁忌事項</h2>
              <p>
                アロマトリートメントは血管内やリンパ管、リンパ節に、これまでずっと溜まったままだった不要な老廃物や、水分をハンドトリートメントにより動かして、排出していきます。
              </p>

              <p>当店ではお客様への医療、治療行為を行いません。</p>

              <p>
                これまでリンパの流れが悪く、老廃物などを溜め込んでいた方は、施術後以下のような症状がみられることがあります。
              </p>

              <p>
                ・だるい
                <br />
                ・眠い
                <br />
                ・のどが渇く
                <br />
                ・熱っぽい
                <br />
                ・尿の色が濃くなる
                <br />
                ・頭痛
                <br />
              </p>

              <p>
                症状が出た場合、たっぷり水分を摂り、トイレの回数を増やし、老廃物をしっかりと出すようにしてください。
                <br />
                ゆっくり休んでいただいた次の日には身体が軽くなっています。
              </p>

              <p>施術後は長時間の自動車の運転は避けてください。</p>
            </div>
          </div>

          <button @click.self="modalClose" data-target="modal01" class="lModal__close" type="button">
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import BaseAccess from "@/components/BaseAccess.vue";
import MenuItem from "@/components/BaseMenuItem.vue";
import Foundation from "@/libs/foundation";

import "swiper/swiper-bundle.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import SwiperCore, {Autoplay, EffectFade} from "swiper/core";
import {Swiper, SwiperSlide} from "swiper/vue";

SwiperCore.use([Autoplay, EffectFade]);

import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Menu",
  components: {
    Footer,
    BaseAccess,
    MenuItem,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      menuList: [
        {
          id: 1,
          title: "oil treatment",
          subTitle: "オイルトリートメント",
          text: `カラダに溜まった老廃物をリンパの流れに沿ってゆっくり流していきます。温かな手のぬくもりとゆったりとした空間で心身共にリラックスしていただけます。`,
          priceList: [
            {
              id: 1,
              price: "60min. / 4,950 yen",
              text: "初めての方でも受けていただきやすいコースです",
            },
            {
              id: 2,
              price: "90min. / 6,600 yen",
              text: "全身のお疲れを癒すスタンダードなコースです",
            },
            {
              id: 3,
              price: "120min. / 9,900 yen",
              text: "お身体だけではなくハンドトリートメントやヘッドケアをプラスして細やかなところまで癒します",
            },
          ],
        },
        {
          id: 2,
          title: "body care",
          subTitle: "ボディケア",
          text: "服を着たまま受けていただけるもみほぐしのコースです。<br/>凝り固まった筋肉をゆっくりもみほぐします。",
          priceList: [
            {
              id: 1,
              price: "30min. / 2,750 yen",
              text: "上半身か下半身を選んでいただくコースです",
            },
            {
              id: 2,
              price: "60min. / 4,400 yen",
              text: "全身をほぐすスタンダードなコースです",
            },
            {
              id: 3,
              price: "90min. / 6,050 yen",
              text: "全身＋お疲れ箇所をしっかりほぐすコースです",
            },
          ],
        },
        {
          id: 3,
          title: "reflexology",
          subTitle: "リフレクソロジー",
          text: "台湾式リフレクソロジー<br/>全身の反射区が集まる足裏を痛気持ちのよい力加減で心地良く丁寧に刺激します。<br/>だるさやむくみの気になる方におすすめです。",
          priceList: [
            {
              id: 1,
              price: "30min. / 2,750 yen",
              text: "足裏の基本的な反射区を中心に刺激します",
            },
            {
              id: 2,
              price: "60min. / 4,400 yen",
              text: "反射区の刺激に加え下半身のストレッチも行います",
            },
          ],
        },
        {
          id: 4,
          title: "head care",
          subTitle: "ヘッドケア",
          text: "凝り固まってカチカチになった頭皮はお顔のむくみ、たるみ、くすみ、シワの原因になります。<br/>一枚で繋がっている頭皮とお顔をゆっくりとほぐします。",
          priceList: [
            {
              id: 1,
              price: "30min. / 2,750 yen",
            },
          ],
        },
        {
          id: 5,
          title: "facial treatment",
          subTitle: "フェイシャルトリートメント",
          text: "美肌はもちろん、意外と気づかないお顔の凝りをオールハンドでほぐします。血行を良くし新陳代謝を高めることでむくみやくすみ、リフトアップにもおすすめです。",
          priceList: [
            {
              id: 1,
              price: "60min. / 4,950 yen",
              text: "クレンジング→洗顔→フェイシャルトリートメント→デコルテ→炭酸パック→ヘッドケア→お仕上げ",
            },
          ],
        },
        {
          id: 6,
          title: "hand treatment",
          subTitle: "ハンドトリートメント<br class='sp'/>（オプションとしてのみ利用可）",
          text: "意外と疲れている手のひらや腕をオイルを使ってほぐします。<br/>他のコースとの組み合わせがおすすめです。",
          priceList: [
            {
              id: 1,
              price: "30min. / 2,750 yen",
            },
          ],
        },
        {
          id: 7,
          title: "horny care",
          subTitle: "角質ケア",
          text: "古くなった角質を丁寧に取り除き滑らかな足裏に整えます。<br/>足湯、保湿ケア",
          priceList: [
            {
              id: 1,
              price: "40min. / 3,500 yen",
            },
          ],
        },
        {
          id: 8,
          title: "extension",
          subTitle: "延長",
          text: "各メニューを延長した際の料金です。",
          priceList: [
            {
              id: 1,
              price: "10min. / 1,100 yen",
            },
          ],
        },
        {
          id: 9,
          title: "*gift ticket",
          subTitle: "ギフトチケット",
          text: "大切な方への贈り物にギフトチケットもご用意しております。",
          priceList: [
            {
              id: 1,
              price: "60min. / 4,950 yen",
            },
            {
              id: 2,
              price: "90min. / 6,600 yen",
            },
          ],
        },
      ],
      topicItem01: [
        {
          id: 1,
          title: "pair course",
          subTitle: "WEB限定ペアコース",
          priceList: [
            {
              id: 1,
              price: "40min. / 4,400 yen",
            },
          ],
        },
      ],
      topicItem02: [
        {
          id: 2,
          title: "rooftop salon",
          subTitle: "屋上サロン",
          text: "通り抜ける風が心地の良い屋上サロンでヘッドケアとリフレクソロジーを受けていただけます。<br/>季節、天候等で屋内での施術となります。詳しくはお問い合わせください。",
        },
      ],
    };
  },
  methods: {
    modalOpen(e) {
      document.getElementById(e.target.getAttribute("data-target")).classList.add("is-open");
      document.body.style.overflow = "hidden";
    },
    modalClose(e) {
      document.getElementById(e.target.getAttribute("data-target")).classList.remove("is-open");
      document.body.style.overflow = "";
    },
    scrollAnime() {
      this.targetArry = gsap.utils.toArray(".js-scroll-target").map((target) => {
        return gsap.from(target, {
          scrollTrigger: {
            trigger: target,
            start: "top 90%",
          },
          opacity: 0,
          scale: 0.95,
          y: 30,
          duration: 2,
          ease: "power4.out",
        });
      });
    },
  },
  mounted() {
    if (document.querySelector(".js-load")) {
      document.querySelector(".js-load").remove();
    }

    Foundation();
    this.scrollAnime();
    ScrollTrigger.refresh();
  },
  beforeUnmount() {
    this.targetArry.forEach((target) => {
      target.scrollTrigger.kill();
      target.kill();
    });
  },
};
</script>

<style lang="scss" scoped>
.lFV {
  padding: 6.9rem 0 4.6rem;
  background-color: #fff;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__logo {
    width: 170px;
    height: auto;
  }

  &__headline {
    margin-top: 10rem;
  }

  &__h1 {
    font-family: var(--lato);
    font-size: 3rem;
    font-weight: 400;
    letter-spacing: 0.1em;
  }
}

.lFixed {
  &__wrapper {
    width: 100%;
  }

  &__block {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;

    &--text {
      position: relative;
      background-color: #fff;
      z-index: 111;

      & .lFixed__scroller {
        height: auto;
      }
    }

    &--image {
      z-index: -1;
    }
  }

  &__scroller {
    position: relative;
    width: 100%;
    height: 100vh;
  }

  &__image {
    position: relative;
    width: 100%;
    height: 100%;

    & img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__imageText {
    position: absolute;
    top: 30px;
    left: 50%;
    z-index: 1;
    font-size: 1.4rem;
    letter-spacing: 0.3em;
    line-height: 2.4;
    font-weight: 400;
    color: #fff;
    writing-mode: vertical-rl;
    transform: translateX(-50%);

    &--pc {
      display: none;
    }
  }
}

.lSection {
  &__text {
    font-size: 1.2rem;
    letter-spacing: 0.1em;
    font-weight: 400;
    line-height: 2.3;
  }

  &__modal {
    max-width: 375px;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 0.1em;
    margin: 5rem auto 0;

    & > button {
      position: relative;
      display: block;
      width: 100%;
      padding: 0 0 1rem;
      text-align: left;

      & .icon {
        position: absolute;
        top: calc(50% - 0.7rem);
        right: 0;
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        background-color: #000;
      }

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #000;
      }
    }

    & + .cAccess__link {
      margin-top: 3rem;
    }
  }

  &__attention {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0.06em;
    margin: 5rem auto 0;
    padding: 30px 40px 30px 30px;
    border: 1px solid #000;
  }

  &--first {
    padding: 7.7rem 42px 5rem;

    & .lSection {
      &__h2 {
        font-family: var(--lato);
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: 0.1em;
        margin-top: 4rem;
      }

      &__content {
        margin-top: 5rem;
      }

      &__menu {
        & dt {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        & dd {
          margin-top: 2rem;
        }

        & + .lSection__menu {
          margin-top: 5rem;
        }
      }

      &__title {
        font-family: var(--lato);
        font-size: 2rem;
        font-weight: 400;
        letter-spacing: 0.1em;
      }

      &__subTitle {
        font-size: 1.3rem;
        font-weight: 400;
        letter-spacing: 0.1em;
        margin-top: 0.8rem;
      }

      &__priceList {
        margin-top: 2.5rem;
      }

      &__priceItem {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & + li {
          margin-top: 1.5rem;
        }
      }

      &__price {
        font-family: var(--lato);
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: 0.05em;
      }

      &__priceText {
        font-size: 1.1rem;
        font-weight: 400;
        letter-spacing: 0.05em;
        margin-top: 0.5rem;
      }

      &__image {
        margin: 7rem calc(50% - 50vw) 0;

        & img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &--second {
    padding: 7rem 42px 7rem;
  }

  &--third {
    display: none;
  }
}

.lTopic {
  margin-top: 7rem;

  &__body {
    margin-top: 3.7rem;
    padding: 30px 0 0;
    border: 1px solid #000;
  }

  &__content {
    padding: 0 20px;
  }

  &__text {
    font-size: 1.2rem;
    letter-spacing: 0.05em;
    font-weight: 400;
    line-height: 2;
    margin-top: 1.5rem;
  }

  &__item {
    & + & {
      margin-top: 4rem;
    }
  }

  &__image {
    width: 100%;
    margin-top: 3rem;

    & img {
      width: 100%;
      object-fit: cover;
    }
  }
}

.lSlider {
  display: none;
}

.lModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1), visibility 1s 0.2s;

  &__overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 30px 30px 0;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 30px;
    }
  }

  &__content {
    position: relative;
    width: 100%;
    background-color: #fff;
    padding: 50px 30px;
  }

  &__block {
    & + & {
      margin-top: 4rem;
    }

    & h2 {
      font-size: 1.2rem;
      font-weight: 700;
      letter-spacing: 0.05em;
    }

    & p {
      font-size: 1.2rem;
      font-weight: 400;
      letter-spacing: 0.05em;
      line-height: 1.65;
      margin-top: 2rem;
    }
  }

  &__close {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 60px;
    height: 40px;
    z-index: 10;

    & span {
      position: absolute;
      top: 50%;
      left: calc(50% - 35px);
      width: 70px;
      height: 1px;
      background-color: #000;
      pointer-events: none;
    }

    & span:first-child {
      transform: rotate(35deg);
    }

    & span:last-child {
      transform: rotate(-35deg);
    }
  }

  &.is-open {
    opacity: 1;
    visibility: visible;
    transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1);
  }
}

@include g.responsive(sm) {
  .lFV {
    padding: 3.5rem 0 11rem;
  }

  .lFixed {
    position: relative;
    z-index: 1;

    &__block {
      &--image02 {
        display: none;
      }
    }

    &__left {
      width: 50%;
      padding-bottom: 10rem;
    }

    &__image {
      display: block;
    }

    &__imageText {
      top: 158px;
      left: g.px-vw(422, 1440);
      color: #000;
      transform: translateX(0);

      &--sp {
        display: none;
      }

      &--pc {
        display: block;
      }
    }
  }

  .lSection {
    &--first {
      max-width: 383px;
      margin: 0 auto;
      padding: 10rem 0 0;

      & .lSection {
        &__image {
          display: flex;
          width: 719px;
          margin: 2.5rem 0 0;
        }
      }
    }

    &--second {
      display: none;
    }

    &--third {
      display: block;
      margin-top: 9rem;
    }

    &__modal {
      margin-top: 6rem;
    }
  }

  .lTopic {
    margin-top: 4.7rem;
  }

  .lSlider {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50%;
    height: 100%;
    background-color: #fff;

    &__wrapper {
      position: sticky;
      top: 0;
      width: 100%;
      height: 100vh;
    }

    &__swiper {
      height: 100%;
    }

    &__item {
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.swiper-slide-active,
      &.swiper-slide-duplicate-active,
      &.swiper-slide-prev {
        & img {
          animation: zoomUp 10s linear 0s 1 normal both;
        }
      }
    }

    &__itemWrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }

  .lFooter {
    margin-top: -1rem;
  }

  .lModal {
    &__overlay {
      padding: 50px 120px;
    }

    &__wrapper {
      overflow: initial;
      display: flex;
      align-items: center;
      max-width: 1200px;
      background-color: #fff;
      margin: 0 auto;

      &::after {
        content: none;
      }
    }

    &__content {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 75px 175px;
    }

    &__block {
      width: 50%;

      & + & {
        margin: 0 0 0 50px;
      }
    }

    &__close {
      position: absolute;
      top: 30px;
      right: 40px;
    }
  }
}

@keyframes zoomUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
</style>
